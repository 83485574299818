<template>
  <a-card :bordered='false'>
      <a-row class="query-row" type='flex' :gutter="12">
         <a-col style="width:220px">
           <span>主订单号：</span>
           <a-input v-model="queryData.orderBn" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:220px">
           <span>子订单号：</span>
           <a-input v-model="queryData.orderSn" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:380px">
           <span>下单时间：</span>
           <a-range-picker 
            v-model="queryData.orderTime"
            :ranges="pickerRange" 
            :show-time="showTime"/>
        </a-col>
        <a-col style="width:200px">
           <span>下单人等级：</span>
           <a-select v-model="queryData.buyerLevel" allowClear placeholder='请选择'>
               <a-select-option value="0">小汇同学</a-select-option>
               <a-select-option value="1">小汇推介官</a-select-option>
               <a-select-option value="2">小汇俱乐部</a-select-option>
               <a-select-option value="3">小汇服务商</a-select-option>
            </a-select>
        </a-col>
        <a-col style="width:180px">
           <span>是否过售后：</span>
           <a-select v-model="queryData.sell" allowClear placeholder='请选择'>
               <a-select-option value="1">是</a-select-option>
               <a-select-option value="0">否</a-select-option>
            </a-select>
        </a-col>
        <a-col style="width:200px">
           <span>下单人：</span>
           <a-input v-model="queryData.buyerId" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:200px">
           <span>分享人：</span>
           <a-input v-model="queryData.referrerId" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:200px">
           <span>直属上级：</span>
           <a-input v-model="queryData.referrerIdSj" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:150px">
           <span>状态：</span>
           <a-select v-model="queryData.status" allowClear placeholder='请选择'>
               <a-select-option v-for="(val,key) in orderStatus" :key="key" :value="key">{{val}}</a-select-option>
            </a-select>
        </a-col>
        <a-col style="width:200px">
           <span>购买方式：</span>
           <a-select v-model="queryData.isSelf" allowClear placeholder='请选择'>
                <a-select-option v-for="(val,key) in isSelf" :key="key" :value="key">{{val}}</a-select-option>
            </a-select>
        </a-col>
        <a-col>
           <a-button @click="() => {this.pageNum = 1;this.loadTableData()}" type='primary' style="margin-right:12px">查询</a-button>
           <a-button type='primary' @click="clearSearchParams()" style="margin-right:12px">重置</a-button>
           <a-button type='primary' @click="exportData()">导出</a-button>
        </a-col>
      </a-row>
      <a-table :columns="columns" :dataSource="tableData" :pagination="false" 
             :loading="loading"
             :scroll="{x: true}"
             size="default">
             <template slot="options" slot-scope="text, record">
               <a @click='showRewardDetail(record)' v-auth='"orderList:rewardDetail"'>查看奖励明细</a>
               <a-divider type="vertical" v-auth='"orderList:orderTime"' />
               <a @click="getOrderTime(record)" v-auth='"orderList:orderTime"'>订单历史时间</a>
             </template>
      </a-table>
      <a-pagination
         style="display: flex; flex-direction: row; justify-content: flex-end; margin:10px 10px 5px 0"
         size="small"
         v-model="pageNum"
         :total="total"
         :showTotal="total => `共 ${total} 条`"
         showSizeChanger
         showLessItems
         showQuickJumper
         :pageSize.sync="pageSize"
         @change="loadTableData"
         @showSizeChange="() => {this.pageNum = 1; this.loadTableData()}"
      />

      <!--导出modal-->
      <ExportModal
         ref="exportModal"
         :exportUrl="exportUrl"
         :params="handleParams()"
         :total="total"
      >
      </ExportModal>

      <!-- 奖励明细弹窗 -->
      <a-modal v-drag-modal :destroyOnClose='true' width='70%' v-model="rewardModal" :footer='null' title='奖励明细' >
         <a-table :columns="rewardColumns" :dataSource="rewardTableData" :pagination="false"
             :loading="loading"
             :scroll="{x: true}"
             size="default">
         </a-table>
      </a-modal>

      <!-- 订单历史时间弹窗 -->
      <a-modal v-drag-modal :destroyOnClose='true' v-model="orderNodeModal" :footer='null' title='订单历史时间' >
         <div class="order-node-modal">
            <a-spin :spinning='orderTimeLoading'>
               <a-card hoverable v-for="item in orderHistoryTime" :key='item.key'>
                     <div :class="{'color-gray': item.colorGray}">
                        <span>{{item.title}}</span>
                        <span>{{moment(item.time * 1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
                     </div>
               </a-card>
            </a-spin>
         </div>
      </a-modal>
  </a-card>
</template>

<script>
import moment from 'moment'
import api from '@/api'
import ExportModal from '@/components/ExportModal'
import { rewardStatus, buyMethods} from '@/config'
export default {
   name:'OrderList',
   components: {
      ExportModal
   },
   data () {
      return {
         exportUrl: api.marketManage.exportOrderList,
         rewardStatus:rewardStatus,
         showTime:{
            format: 'HH:mm:ss',
            defaultValue:[moment('00:00:00', 'HH:mm:ss'),moment('23:59:59', 'HH:mm:ss')]
         },
         pageNum:1,
         total:0,
         pageSize:10,
         loading:false,
         columns:[
            {
               title: '序号',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  return index + 1
               }
            },
            {
               title: '主订单号',
               dataIndex: 'orderBn',
               align: 'center',
               ellipsis:true
            },
            {
               title: '子订单号',
               dataIndex: 'orderSn',
               align: 'center',
               ellipsis:true
            },
            {
               title: '订单类型',
               dataIndex: 'orderType',
               align: 'center',
               ellipsis:true,
               customRender: text => {
                  let textMap = {
                     '0': '门槛外',
                     '1': '门槛内',
                     '2': '跨门槛',
                  }
                  return textMap[text]
               }
            },
            {
               title: '订单价格',
               dataIndex: 'price',
               align: 'center',
               ellipsis:true
            },
            {
               title: '奖励积分',
               dataIndex: 'promotionPoint',
               align: 'center',
               ellipsis:true
            },
            {
               title: '小汇豆',
               dataIndex: 'beans',
               align: 'center',
               ellipsis:true
            },
            {
               title: '下单人',
               dataIndex: 'buyerId',
               align: 'center',
               ellipsis:true
            },
            {
               title: '下单人等级',
               dataIndex: 'buyerLevel',
               align: 'center',
               ellipsis:true,
               customRender: (text,record) => {
                  return ['小汇同学','小汇推介官','小汇俱乐部','小汇服务商'][text] || ''
               }
            },
            {
               title: '下单时间',
               dataIndex: 'payTime',
               align: 'center',
               ellipsis:true,
               customRender: text => {
                  return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
               }
            },
            {
               title: () => {
                  return (
                     <div>
                        <span style={{marginRight: '2px'}}>分享人</span>
                        <a-tooltip>
                           <template slot="title">
                              <div>1.直播订单分享人：通过在直播间分享商品给他人购买、分享直播给他人观看并促成交易的用户。</div>
                              <div>2.非直播订单分享人：通过商城分享商品给他人购买的用户。</div>
                           </template>
                           <a-icon type="question-circle" />
                        </a-tooltip>
                     </div>
                  )
               },
               dataIndex: 'referrerId',
               align: 'center',
               ellipsis:true,
               customRender: (text, { roomId, liveReferrerId }) => {
                  return Number(roomId) > 0 ? liveReferrerId : text
               }
            },
            {
               title: '下单时直属上级',
               dataIndex: 'pusherUserStr',
               align: 'center',
               ellipsis:true
            },
            {
               title: '状态',
               dataIndex: 'status',
               align: 'center',
               ellipsis:true,
               customRender: (text, record) => {
                  if (record.refundStatus == '1') return '退款中'
                  return this.orderStatus[text]
               }
            },
            {
               title: '购买方式',
               dataIndex: 'isSelf',
               align: 'center',
               ellipsis:true,
               customRender: text => {
                  return this.isSelf[text]
               }
            },
            {
               title: '商品活动标签',
               dataIndex: 'promotionName',
               align: 'center',
               ellipsis:true
            },
            {
               title: '是否直播订单',
               dataIndex: 'roomId',
               align: 'center',
               ellipsis:true,
               customRender: text => {
                  return Number(text) > 0 ? '是' : '否'
               }
            },
            {
               title: '是否过售后',
               dataIndex: 'isAftersale',
               align: 'center',
               ellipsis:true,
               customRender: (text,record) => {
                  return ['否','是'][text] || ''
               }
            },
            {
               title: '操作',
               align: 'center',
               ellipsis: true,
               scopedSlots: { customRender: 'options' }
               // customRender: (text, record) => {
               //    return <a onClick={() => this.showRewardDetail(record)}>查看奖励明细</a>
               // }
            }

         ],
         tableData:[],
         queryData: {
            orderBn:undefined,
            orderSn:undefined,
            buyerId:undefined,
            referrerId:undefined,
            referrerIdSj:undefined,
            buyerLevel:undefined,
            sell:undefined,
            status:undefined,
            isSelf:undefined,
            orderTime:[],
         },
         rewardModal: false,
         orderNodeModal: false,
         rewardColumns:[
            {
               title: '主订单号',
               dataIndex: 'orderBn',
               align: 'center',
               ellipsis: true,
               customRender: () => this.currentRow.orderBn
            },
            {
               title: '子订单号',
               dataIndex: 'orderSn',
               align: 'center',
               ellipsis: true,
               customRender: () => this.currentRow.orderSn
            },
            {
               title: '分享人',
               dataIndex: 'referrerId',
               align: 'center',
               ellipsis: true,
               customRender: () => {
                  const { roomId, liveReferrerId, referrerId } = this.currentRow
                  return Number(roomId) > 0 ? liveReferrerId : referrerId
               }
            },
            {
               title: '受益人',
               dataIndex: 'mobile',
               align: 'center',
               ellipsis:true
            },
            {
               title: '奖励类型',
               dataIndex: 'rewardType',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  // 受益人没激活 奖励积分显示为销售积分
                  // if (text && record.activeStatus != 1 && Number(text) <= 8) {
                  //    return '销售积分'
                  // }
                  let textMap = {
                     '1':'销售积分',
                     '2':'销售积分',
                     '3':'奖励积分',
                     '4':'奖励积分销售奖',
                     '5':'奖励积分小汇俱乐部补贴',
                     '6':'奖励积分小汇服务商补贴',
                     '7':'奖励积分小汇俱乐部培育奖',
                     '8':'奖励积分小汇服务商培育奖',
                     '9':'小汇豆销售奖',
                     '10':'小汇豆俱乐部补贴',
                     '11':'小汇豆服务商补贴',
                     '12':'小汇豆俱乐部培育奖',
                     '13':'小汇豆服务商培育奖',
                     '14':'出单奖励',
                     '15':'加码奖',
                     '16':'新人首单奖',
                     '26':'企业购推荐奖'
                  }
                  let orderTypeMap = {
                     '0': '门槛外',
                     '1': '门槛内',
                  }
                  let orderTypeStr = orderTypeMap[record.orderType] ? `(${orderTypeMap[record.orderType]})` : ''
                  return textMap[text] + orderTypeStr
               }
            },
            {
               title: '数值',
               dataIndex: 'rewardAmount',
               align: 'center',
               ellipsis:true
            },
            {
               title: '奖励发放状态',
               dataIndex: 'status',
               align: 'center',
               ellipsis:true,
               customRender: text => {
                  // 已取消显示红色 20为已取消
                  return (
                     <span style={{color:text === 20 ? '#ff4d4f' : ''}}>{this.rewardStatus[text]}</span>
                  )
               }
            },
            {
               title: '下发时间',
               dataIndex: 'time',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  if (record.status == '20' || record.status == '3') return '-'
                  return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : '-'
               }
            },
            {
               title: '取消时间',
               dataIndex: 'time',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  if (record.status != '20') return '-'
                  return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : '-'
               }
            },
            {
               title: '退单时间',
               dataIndex: 'time',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  if (record.status != '3') return '-'
                  return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : '-'
               }
            },
            
         ],
         rewardTableData: [],
         currentRow: {},
         //订单状态
         orderStatus: {
            '-1': '退款成功',
            '1': '已支付',
            '2': '已收货',
            // '3':'已完成',//20220420迭代删除已完成状态 添加退款中
            '-2': '退款中' // 选择-2时 实际传给后端的是  refundStatus = 1
         },
         //购买方式
         isSelf: buyMethods,
         orderHistoryTime: [],
         orderTimeLoading: true,
         pickerRange:{}
      }
   },
   mounted () {
      this.initSearchParams()
      this.loadTableData()
   },
   methods:{
      moment,
      exportData () {
         this.$refs.exportModal.show()
      },
      handleParams () {
         let {orderBn,orderSn,buyerId,referrerId,referrerIdSj,buyerLevel,sell,orderTime,status,isSelf} = this.queryData
         let startTime = orderTime[0] ? orderTime[0].valueOf() / 1000 : undefined
         let endTime = orderTime[1] ? orderTime[1].valueOf() / 1000 : undefined
         orderBn = orderBn || undefined
         orderSn = orderSn || undefined
         buyerId = buyerId || undefined
         referrerId = referrerId || undefined
         referrerIdSj = referrerIdSj || undefined
         let p = {
            orderBn,orderSn,buyerId,referrerId,referrerIdSj,buyerLevel,sell,startTime,endTime,isSelf,status,
            pageNum:this.pageNum,
            pageSize:this.pageSize
         }
         if (status == '-2') {
            // 选择退款中-2  时  传给后端的是refundStatus = 1  (￣ー￣)(ŎдŎ；)(/•ิ_•ิ)/
            delete p.status
            p.refundStatus = '1'
         }
         return p
      },
      // 初始化参数，第一次进入页面只查当天时间的
      initSearchParams(){
         const day_start = moment().startOf('day')
         const day_end = moment().endOf('day')
         this.pickerRange = { 
            '今日': [moment().startOf('day'), moment().endOf('day')], 
            '本周': [moment().startOf('week'), moment().endOf('week')], 
            '本月': [moment().startOf('month'), moment().endOf('month')]
         }
         // this.queryData.orderTime = [day_start,day_end]
      },
      loadTableData () {
         this.loading = true
         api.marketManage.orderList(this.handleParams()).then(res => {
            if (res.code === '00000') {
               this.tableData = res.data.dataList
               this.total = res.data.total
            } else {
               this.$message.error(res.msg)
            }
         }).finally(() => this.loading = false)
      },
      showRewardDetail (record) {
         this.currentRow = record
         this.loading = true
         this.rewardModal = true
         let params = {list:[record.orderSn]}
         api.marketManage.rewardSecondList(params).then(res => {
            if (res.code === '00000') {
               this.rewardTableData = res.data.dataList
            } else {
               this.$message.error(res.msg)
            }
         }).finally(() => {this.loading = false})
      },
      clearSearchParams(){
         this.queryData = {
            orderBn:undefined,
            orderSn:undefined,
            buyerId:undefined,
            referrerId:undefined,
            referrerIdSj:undefined,
            buyerLevel:undefined,
            sell:undefined,
            status:undefined,
            isSelf:undefined,
            orderTime:[],
         }
      },
      getOrderTime ({orderSn}) {
         this.orderNodeModal = true
         this.orderTimeLoading = true
         api.marketManage.orderTime({orderSn}).then(res => {
            if (res.code !== '00000') return this.$message.error(res.msg)
            let timeArr = [
               {
                  title: '下单时间',
                  key: 'payTime'
               },
               {
                  title: '收货时间',
                  key: 'endTime'
               },
               {
                  title: '申请退款',
                  key: 'refundApplyTime'
               },
               {
                  title: '取消退款',
                  key: 'refundCancelTime'
               },
               {
                  title: '退款成功',
                  key: 'refundSuccessTime'
               },
               {
                  title: '售后时间',
                  key: 'afterSalesTime'
               },
            ]
            this.orderHistoryTime = timeArr.map(i => {
               return {
                  ...i,
                  time: res.data[i.key],
                  // 若订单在收货后发起申请退款( refundStatus=1)，售后时间置灰；
                  colorGray: i.key === 'afterSalesTime' && res.data.refundStatus === 1,
                  title: i.key === 'endTime' ? res.data.isUserConfirm === 0 ? `${i.title}（系统自动确认）` : `${i.title}（手动确认）` : i.title
               }
            }).sort((a, b) => a.time - b.time).filter(c => c.time)
            this.orderTimeLoading = false
         })
      },
   }
}
</script>

<style scoped lang='less'>
   .order-node-modal /deep/ .ant-card {
      margin-bottom: 12px;
      cursor: auto;
      .ant-card-body {
         & > div {
            display: flex;
            justify-content: space-between;
         }
         & > .color-gray {
            color: #d9d9d9;
         }
      }
   } 
</style>